.Product-Row {
    height: 130px;
    border-top: 1px solid rgb(215, 228, 235);
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-top: 30px; */
}

.Product-Row img {
    /* width: 125px; */
    object-fit: cover;
    width: 115px;
    height: 90px;
}

.Product-Row .Wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.Product-Row h5 {
    margin-top: 0px;
    margin-bottom: 0px;
    /* color: #000; */
    color: var(--text-color);
    font-size: 16px;
}

.Product-Row p {
    color: var(--secondary-text-color);

    /* color: #6D6D6D; */
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 1.5px;
    margin-top: 5px;
}

.Product-Row .Pricing {
    margin-left: auto;
    margin-right: 0px;;
}

.Product-Row button {
    /* background-color: var(--background-color); */
    background-color: rgb(234, 239, 242);
    border-radius: 6px;
    height: 47px;
    width: 175px;
    border:none;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    /* color: var(--accent-color); */
}


.Mobile-ProductRow-Image {
    display: none;
}

@media screen and (max-width: 800px) {
    .Mobile-ProductRow-Image {
        display: block;
    }

    .Mobile-ProductRow-Image img {
        width: 80px;
        height: 80px;
    }

    .Product-Row {
        height: 230px;
        /* flex-direction: column; */
        align-items: center;
        /* justify-content: center; */
    }

    .Desktop-ProductRow-Image {
        display: none;
    }

    .Product-Row .Wrapper {
        flex-direction: column;
        align-items: flex-start;
        /* justify-content: center; */
    }

    .Product-Row .Pricing {
        margin-left: 0px;;
        margin-top: 15px;
    }
}
body.Cart-Open {
    overflow: hidden !important;
}

.Cart-Wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    z-index: 1;
}

.Cart {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 500px;
    background-color: var(--background-color);

    /* background-color: #FFFFFF; */
    box-shadow: 1px 4px 25px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 800px) {
    .Cart {
        width: 100%;
        overflow: hidden !important;
    }
}

.Cart-Header {
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    /* box-shadow: 4px 0px 25px rgba(0, 0, 0, 0.05); */
}

.Cart-Header div {
    display: flex;
    flex-direction: column;
}

.Cart-Header h5 {
    font-weight: 500;
    font-size: 15px;
    /* color: #4F4F4F; */
    color: var(--text-color);
    margin: 0px;
}

.Cart-Header p {
    margin: 0px;
    font-weight: 500;
    font-size: 12px;
    /* color: #797979; */
    color: var(--text-color);

}

.Cart-Header button {
    cursor: pointer;
    height: 46px;
    width: 95px;
    border: none;
    outline: none;
    background: var(--background-color);

    /* background: #FFFFFF; */
    box-shadow: inset 0px 4px 15px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    position: relative;
    text-align: right;
    padding-right: 20px;
    color: var(--text-color);
    /* color: #4F4F4F; */
    font-weight: 600;
}

.Cart-Header button img {
    position: absolute;
    top: 12px;
    left: 15px;
    width: 23px;
    height: 23px;
}

.Cart-Body {
    /* height: 85%; */
    /* height: 550px; */
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.Cart-Body::-webkit-scrollbar {
    display: none;
}


.Cart-Branding {
    margin: 0px;
    height: 80px;
    box-shadow: 4px 0px 25px rgba(0, 0, 0, 0.05);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    /* justify-content: space-evenly; */
}

.Cart-Branding img {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    object-fit: cover;
}

.Cart-Branding p {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--text-color);

    /* color: #797979; */
    margin: 0px;
}

.Cart-Branding h5 {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-color);
    /* color: #535353; */
    margin: 0px;
}

/* Cart products */
.Cart-Products {
    margin-top: 15px;
}

.Cart-Product {
    display: flex;
    flex-direction: row;
    height: 120px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Cart-Product img {
    width: 75px;
    /* height: 75px; */
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1));
    border-radius: 25px;
}

.Cart-Product div {
    display: flex;
    flex-direction: column;
}

.Cart-Product h5 {
    margin: 0px;
    font-weight: 700;
    font-size: 14px;
    /* color: #393939; */
    color: var(--text-color);
}


.Cart-Product a {
    margin: 0px;
    font-weight: 700;
    font-size: 12px;
    color: var(--accent-color);
}

.Cart-Product h2 {
    margin: 0px;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    font-weight: bold;
    font-size: 18px;
    color: var(--text-color);
    /* color: #000000; */
    
}

.Cart-Product p {
    margin: 0px;
    font-size: 12px;
    color: var(--secondary-text-color);
    /* color: #6D6D6D; */
}

.Remove-Cart-Item {
    cursor: pointer;
    border: none;
    font-size: 12px;
    color: var(--accent-color);
    width: 60px;
    text-align: left;
    padding-left: 0px;
    background-color: transparent;
}

.Cart-Product select, .Product-Amount {
    border-radius: 25px;
    height: 30px;
    width: 50px;
    border: 1px solid #6D6D6D;
    font-size: 12px;
    padding-top: 5px;
    text-align: center;
    color: var(--text-color);

}

/* Cart recos */
.Cart-Recos {
    padding-top: 15px;
    /* margin-top: auto; */
    padding-bottom: 15px;
}

.Cart-Recos button {
    background: var(--accent-color);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08), inset 0px 2px 15px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    border:none;
    margin-top: 5px;
    height: 30px;
    width: 90px;
  
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    color: #EEEEEE;
    cursor: pointer;
}

.Cart-Recos .Cart-Products {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.Cart-Recos .Cart-Products::-webkit-scrollbar {
    display: none;
}


.Cart-Recos .Cart-Product {
    min-width: 300px;
}

.Cart-Recos .Cart-Product h2 {
    font-size: 12px;
}

.Cart-Recos .Reco-Name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.Cart-Recos .Cart-Product h5 {
    font-size: 12px;
}

/* Cart footer */
.Cart-Footer {
    /* margin-top: auto; */
    /* position: absolute; */
    /* bottom:0px; */
    height: 92px;
    /* background: #FFFFFF; */
    /* box-shadow: 4px 0px 25px rgba(0, 0, 0, 0.05); */
    display: flex;
    align-items: center;
    justify-content: center;
}



.Cart-Footer button {
    width: 207px;
    height: 46px;
    background: var(--accent-color);
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    color: #fff;
    border:none;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.Cart-Footer button:active {
    opacity: 0.7 !important;
}
/* Widget Icon */
.Heed-Widget {
    position: fixed;
    bottom: 35px;
    right: 35px;
    z-index: 9999;
    border-radius: 100px;
    width: 70px;
    height: 70px;
    background-color: rgba(255, 234, 224, 1);
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.12));
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.Logo-Container,
.Close-Container {
    width: 100%;
    text-align: center;
}


.Logo-Container,
.Close-Container {
    transition: all .35s ease-in-out;
}

.Heed-Widget.active .Logo-Container {
    transform: rotate(90deg) scale(0);
    opacity: 0;
}

.Heed-Widget:not(.active) .Close-Container {
    transform: rotate(-90deg) scale(0);
    opacity: 0;
}

.Logo-Widget {
    margin-top: 22.5px;
}

.Close-Widget {
    width: 35px;
    margin-top: -70px;
}



/* Widget Body */
.Heed-Widget-Body {
    position: fixed;
    bottom: 125px;
    right: 35px;
    z-index: 9999;
    /* height: 500px; */
    width: 350px;
    /* background: radial-gradient(142.05% 142.05% at 56.6% 103.81%, rgba(255, 210, 190, 0.5) 50.54%, rgba(255, 181, 148, 0) 100%) */
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    /* , #FFF2EC; */
    background-color: rgba(255, 234, 224, 1);
    box-shadow: 24px 24px 48px rgba(196, 196, 196, 0.5), inset 12px 8px 16px rgba(255, 255, 255, 0.5), inset -12px -8px 16px rgba(255, 213, 194, 0.25);
    border-radius: 60px;
    display: flex;
    flex-direction: column;

    transition: all 1s;

}


.Heed-Widget-Body::-webkit-scrollbar {
    /* display: none; */
  }


.Heed-Widget-Body-Header {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    margin-top: 40px;
}

.Heed-Widget-Body-Header button {
    background: #FFF2EC;
    border: 1px solid #E6E6E6;
    box-shadow: 19.7217px 19.7217px 39.4435px rgba(121, 121, 121, 0.25), inset -9.86087px -6.57391px 13.1478px rgba(255, 213, 194, 0.25), inset 9.86087px 6.57391px 13.1478px rgba(255, 255, 255, 0.25);
    border-radius: 8px;
    color: #6C6C6C;
    font-weight: 900;
    width: 30px;
    height: 30px;
    font-size: 12px;
    cursor: pointer;
}

.Heed-Widget-Body-Header button:hover {
    opacity: 0.8;
}

.Heed-Widget-Menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}


.Heed-Widget-Body h1 {
    font-size: 32px;
    color: #6C6C6C;
    font-weight: bold;
    line-height: normal;
    text-align: left;
    font-weight: 800;
}

.Heed-Widget-Body .Find-Product {
    background: #5096FF;
    border: 0.5px solid #9F9F9F;
    box-shadow: 24px 24px 48px rgba(162, 162, 162, 0.5), inset -12px -8px 16px #2C80FF, inset 12px 8px 16px rgba(240, 240, 240, 0.4);
    border-radius: 100px;
    color: #fff;
    font-size: 18px;
    width: 250px;
    height: 50px;
    margin-top: 30px;
    cursor: pointer;
    font-weight: 800;
    z-index: 1;
    ;
}

.Heed-Widget-Body .Find-Product:hover {
    opacity: 0.8;
}

.Heed-Widget-Body .Start-Chat {
    background: #FFF2EC;
    border: 1px solid #E6E6E6;
    box-shadow: 19.6154px 19.6154px 39.2308px rgba(197, 197, 197, 0.5), inset -9.80769px -6.53846px 13.0769px rgba(255, 207, 185, 0.35), inset 9.80769px 6.53846px 13.0769px rgba(255, 255, 255, 0.25);
    border-radius: 100px;
    color: #6C6C6C;
    font-size: 14px;
    width: 200px;
    height: 50px;
    /* margin: auto; */
    margin-top: 15px;
    cursor: pointer;
    font-weight: 800;
    z-index: 2;
}

.Heed-Widget-Body .Start-Chat:hover {
    opacity: 0.8;
}



/* Heed Chat */
.Heed-Widget-Chat {
    /* height: 100%; */
    /* margin-top: 110px; */
    /* flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll; */
    height: 100%;
    overflow-y: scroll;

}

.Heed-Widget-Chat::-webkit-scrollbar {
    /* display: none; */
  }



/* MESSAGES */
.Heed-Widget-Chat-Messages {
    flex: 1;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    /* margin-top: 90px; */
}


.Heed-Widget-Chat-Message-Row {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 15px;
}

.Heed-Widget-Chat-Bubble {
    max-width: 75%;
    border-radius: 30px;
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px;
    font-weight: 800;
}

.My-Message {
    justify-content: flex-end;
}

.My-Message .Heed-Widget-Chat-Bubble {
    background: #5096FF;
    border: 1.10497px solid #9F9F9F;
    box-shadow: 26.8045px 26.8045px 53.609px rgba(121, 121, 121, 0.25), inset -13.4022px -8.93483px 17.8697px #2D81FF, inset 13.4022px 8.93483px 17.8697px rgba(255, 255, 255, 0.25);
    color: #fff;
}

.Other-Message .Heed-Widget-Chat-Bubble {
    background: #EFEFEF;
    border: 1.10497px solid #E6E6E6;
    box-shadow: 26.8045px 26.8045px 53.609px rgba(121, 121, 121, 0.25), inset -13.4022px -8.93483px 17.8697px rgba(183, 183, 183, 0.25), inset 13.4022px 8.93483px 17.8697px rgba(255, 255, 255, 0.25);
    color: #8A8A8A;
}


/* CARDS */

.Heed-Widget-Chat-Cards-Container {
    margin-bottom: 30px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Heed-Widget-Chat-Cards-Container h5 {
    text-align: center;
    font-size: 14px;
    color: #6C6C6C;
    font-weight: 800;
    text-shadow: 2px 2px 8px rgba(104, 104, 104, 0.25);
}

.Heed-Widget-Chat-Cards {
    display: flex;
    flex-direction: row;
    overflow: scroll;

    margin-top: 15px;
    padding-left: 30px;
    padding-right: 15px;
/* 
    background-color: green;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px; */

}

.Heed-Widget-Chat-Card {
    height: 200px;
    min-width: 200px;
    margin-left: 0px;
    margin-right: 30px;
    background: #FFF2EC;
    border: 1px solid #E6E6E6;
    /* box-shadow: 24px 24px 48px rgba(200, 169, 156, 0.25), inset -12px -8px 16px rgba(255, 213, 194, 0.35), inset 12px 8px 16px rgba(255, 255, 255, 0.25); */
    border-radius: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.Heed-Widget-Chat-Card:hover {
    opacity: 0.8;
}



.Heed-Widget-Chat-Card img {
    max-height: 130px;
    max-width: 180px;
}

.Heed-Widget-Chat-Card h6 {
    font-size: 14px;
    color: #6C6C6C;
    font-weight: 800;
    text-shadow: 2px 2px 8px rgba(104, 104, 104, 0.25);
}


/* PRODUCT CARDS */
.Heed-Widget-Product-Cards-Container {
    /* margin-top: 30px; */
    margin-top: 30px;
    display: flex;
    flex:1;
    flex-direction: column;
    width: 100%;
}


.Heed-Widget-Chat-Products {
    display: flex;
    flex-direction: row;
    overflow: scroll;

    padding-left: 30px;
    padding-right: 15px;
    
}

.Widget-Product-Card {
    min-width: 220px;
    height: 270px !important;
    background: #FFF2EC !important;
    border: 1px solid #E6E6E6 !important;
    /* box-shadow: 24px 24px 48px rgba(200, 169, 156, 0.25), inset -12px -8px 16px rgba(255, 213, 194, 0.35), inset 12px 8px 16px rgba(255, 255, 255, 0.25) !important; */
    border-radius: 30px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Widget-Product-Card img {
    width: 120px !important;
    height: 120px !important;
    margin-bottom: 5px;
}




.Widget-Product-Card:hover {
    opacity: 0.8;
}



.Heed-Widget-Product-Buttons {
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.Heed-Widget-Product-Buttons button {
    margin-left: 5px;
    margin-right: 5px;
    height: 40px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 800;
    cursor: pointer;
}





/* INPUT */
.Heed-Widget-Chat input {
    background: #EFEFEF;
    border: 1.10497px solid #E6E6E6;
    box-shadow: 26.8045px 26.8045px 53.609px rgba(121, 121, 121, 0.25), inset -13.4022px -8.93483px 17.8697px rgba(183, 183, 183, 0.25), inset 13.4022px 8.93483px 17.8697px rgba(255, 255, 255, 0.25);
    border-radius: 88.3978px;
    padding: 12px;
    outline: none;
    font-weight: 800;
    color: #8A8A8A;
    font-size: 13px;
}


.Heed-Widget-Chat input::placeholder {
    color: #8A8A8A;
}


.Heed-Widget-Body-Footer {
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    background: #FFF2EC;
    box-shadow: 24px 24px 48px rgba(231, 231, 231, 0.25), inset 12px 8px 16px rgba(255, 255, 255, 0.5);
    border-radius: 0px 0px 60px 60px;
    display: flex;
    padding-top: 15px;
    justify-content: center;
    font-weight: 800;
    color: #6C6C6C;
    font-size: 12px;

}

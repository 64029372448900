.Header {
    height: 65px;
    /* width: 100%; */
    /* background-color: #F8F8F8; */
    background-color: var(--background-color);
    /* border: 0.7px solid #eee; */
    border-bottom: 0.3px solid #D2D2D2;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 1;
}

.Header-Container {
    height: 65px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1400px;
    margin: auto;
}

/* Desktop components */
.Header-Link {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-color);
    /* color: #000000; */
}

.Mobile-Header .Menu-Status {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}


/* Mobile components */
.Menu-Title {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 25px;
    color: #AAAAAA;
    margin-right: 5px;
}

.Menu-Status {
    font-style: normal;
    font-weight: 800;
    font-size: 11px;
    line-height: 15px;
    margin-right: 5px;
}

.Menu-Info {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding-top: 2.5px;
}

.Menu-Info img {
    width: 18px;
    height: 18px;
}

.Cart-Button {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 15px;
    width: 80px;
    height: 46px;
    /* background: #FFFFFF; */
    background: var(--background-color);
    border-radius: 8px;
    border: 0.3px solid var(--secondary-text-color);;
    /* box-shadow: inset 0px 4px 15px rgba(0, 0, 0, 0.2); */
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Cart-Button .Cart-Quantity {
    /* margin-left: 7px; */
    margin-right: 7px;
    background-color: var(--text-color);;
    /* background-color: #000; */
    border-radius: 3px;
    width: 23px;
    height: 23px;
    font-weight: 800;
    font-size: 11px;
    line-height: 15px;
    /* color: #FFFFFF; */
    color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Desktop vs Mobile stylings */

@media screen and (min-width: 800px) {
    .Mobile-Header {
        display: none !important;
    }
}

@media screen and (max-width: 800px) {
    .Product-Page .Header {
        width: 100%;
        position: fixed;
    }

    .Product-Page .Navigation-Header {
        position: relative;
    }

    .Desktop-Header {
        display: none !important;
    }
}


/* Dropdown button */
.dropbtn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-color);
    /* color: #000000; */
    border: none;
    background-color:transparent;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--background-color);

    /* background-color: #fff; */
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;
    /* top:25px; */
}

.dropdown-content a {
    /* color: black; */
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-color);
    /* color: #000000; */
}

.dropdown-content a:hover {
    background-color: var(--secondary-text-color);
    color: var(--accent-color) !important;
    /* background-color: #ddd; */
}

.dropdown:hover .dropdown-content {
    display: block;
}

/* .dropdown:hover .dropbtn {background-color: #3e8e41;} */

.green { 
    color: #4AB119;
    border:none !important; 
}

.red {
    color: red;
    border:none !important; 
}
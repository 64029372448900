a {
  text-decoration: none;
}

html, body {
  background-color: var(--background-color);

}

.App {
  /* background-color: #fff; */
  background-color: var(--background-color);
  height: 100%;
  min-height: 100vh;
  margin: 0px;
}

.App-Body {
  padding: 45px;
  max-width: 1400px;
  margin: auto;
  padding-bottom: 60px;
}

.App-Body .Product-Stack-Title {
  margin-top: 30px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  /* color: #000000; */
  color: var(--text-color);
}

/* Retailer branding section top of menu */

.Retailer-Branding {
  text-align: center;
  position: relative;
}

.Retailer-Branding h1 {
  margin-top: 30px;
  font-weight: 800;
  font-size: 32px;
  /* color: #6D6D6D; */
  color: var(--secondary-text-color);
  text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
}

.Banner {
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
  width: 100%;
  margin-top: 30px;
}

.Product-Search {
  margin-top: 30px;
  /* background: #FFFFFF; */
  background-color: var(--background-color);
  box-shadow: 0px 4px 4px rgba(108, 108, 108, 0.15);
  border-radius: 5px;
  width: 45%;
  height: 50px;
  border: none;
  border: 1px solid #D6D6D6;
  outline: none;
  padding-left: 18px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* color: #000; */
  color: var(--text-color);
}

.Product-Search::placeholder {
  /* color: #A5A5A5; */
  color: var(--text-color);
}

.Search-Results {
  left: 0;
  right: 0;
  position: absolute;
  height: 350px;
  width: 45%;
  margin:auto;
  margin-top: 15px;

  /* background: #fff; */
  background: var(--background-color);

  box-shadow: 0px 4px 4px rgba(108, 108, 108, 0.15);
  border-radius: 5px;
  overflow-y: scroll;
  border: 1px solid #D6D6D6;
  padding: 10px;
}

/* Menu */
.Menu-Stack {
  margin-top: 45px;
}

/* Product cards */
.Feeling-Stack {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.Product-Stack {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Product-Stack::-webkit-scrollbar {
  background-color: #eee;
  border-radius: 100px;
  height: 8px;
}


.Product-Stack::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #D2D2D2;
  /* box-shadow: 0 0 1px rgba(255, 255, 255, .5); */
}



@media screen and (max-width: 800px) {
  .Retailer-Branding h1 {
    font-size: 26px;
  }

  .App-Body .Product-Stack-Title {
    font-size: 20px;
  }

  .Product-Search {
    width: 80%;
  }
  .Search-Results {
    width: 80%;
  }
}

/* Menu Navi */
.Menu-Navigation {
  height: 100%;
  width: 100% !important;
  position: fixed;
  z-index: 999;
  /* background-color: #fff; */
  background-color: var(--background-color);

  overflow: auto;
}

.Menu-Navigation-Body {
  padding: 15px;
  max-width: 1400px;
  margin: auto;
  padding-bottom: 60px;

}

.Menu-Navigation-Pages {
  padding-bottom: 10px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 0.7px solid #AAAAAA;
  display: flex;
  justify-content: space-around;
  /* color: #878787; */
  color: var(--secondary-text-color);
}

.Menu-Navigation-Pages span {
  cursor: pointer;
}

.Menu-Navigation-Pages div {
  width: 150px;
  text-align: center;
}

.Menu-Navigation-Pages .active {
  color: var(--accent-color) !important;
}

.Menu-Navigation-Categories ul {
  list-style-type: none;
  margin-top: 30px;
}


.Menu-Navigation-Categories li {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 30px;
  cursor: pointer;
  color: var(--text-color);
}

.Menu-Navigation-Info {
  display: flex;
  flex-direction: row;
  padding: 15px;
  max-width: 1400px;
  margin: auto;
  padding-bottom: 60px;
  padding-left: 30px;
  /* justify-content: space-between; */
  /* background-color: red; */
}

.Menu-Navigation-Info .Info-Box {
  flex: 1;
}

.Menu-Navigation-Info .list-group {
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px;
}


.Menu-Navigation-Info .list-group li {
  margin-bottom: 5px;
}

.Menu-Navigation-Info li {
  color: var(--secondary-text-color);
}

.Info-Box h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 0px;
  color: var(--text-color);
}

.Info-Box p {
  margin-top: 5px;
  font-size: 14px;
  /* color: #000; */
  color: var(--text-color);
}

.Info-Box h6 {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 0px;
  color: var(--secondary-text-color);
  /* color: #6D6D6D; */
}

.Info-Box button {
  width: 150px;
  height: 46px;
  background: var(--accent-color);
  box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  color: #fff;
  border: none;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.Info-Box:nth-child(2) {
  margin-left: 60px;
}

/* 538 */
@media screen and (max-width: 800px) {
  .Menu-Navigation-Info {
    flex-direction: column;
    align-items: flex-start;
  }

  .Info-Box:nth-child(2) {
    margin-left: 0px;
  }
}


/* Footer */
.App-Footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  color: white;
  text-align: center;
  background-color: #F3F3F3;
  border: 0.7px solid #eee;
  border-radius: 12px;
}

.App-Footer p {
  margin-top: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #7E7E7E;
}

.App-Footer span {
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #FFBE5B;
  letter-spacing: -0.05em;
}

/* Product Page */

.Desktop-Product-Image {
  display: none;
}

/* Desktop product page */
@media screen and (min-width: 800px) {

  .Desktop-Product-Image {
    padding-top: 30px;
    display: flex;
    height: 400px;
  }

  .Product {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
  }

  .Product-Body {
    padding-top: 60px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    max-width: 600px !important;
  }
}

.Product .Back-Button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: var(--accent-color);
  font-weight: 600;
  font-size: 16px;
  position: absolute;
  left: 90px;
  top: 30px;
  /* z-index: 1; */
  /* margin-left: -30px; */
  /* top: 80px; */
}


.Product-Image {
  /* background-color: red; */
  min-height: 350px;
  background-repeat: no-repeat;
  background-size: 175px 175px;
  background-position: center 120px;
  background-attachment: fixed;
}



.Product {
  height: 100%;
  position: relative;
  max-width: 1400px;
}



.Product-Body {
  padding: 30px;
  /* width: 100%; */
  /* background: #FFFFFF; */
  background: var(--background-color);

  box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.Product-Body h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: normal;
  /* color: #000000; */
  color: var(--text-color);
  margin: 0px;
}

.Product-Info {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}

.Product-Brand {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--secondary-text-color);
  /* color: #A5A5A5; */
  margin: 0px;
}

.Product-Plant {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* color: #878787; */
  color: var(--secondary-text-color);
}

.Product-Price {
  margin-top: 30px;
  margin-right: 30px;
  font-style: bold;
  font-weight: 600;
  font-size: 24px;
  color: var(--accent-color);
}

.Product-Size {
  /* background: #FFFFFF; */
  background: var(--background-color);

  border: 1px solid var(--accent-color);
  box-sizing: border-box;
  border-radius: 10px;
  width: 80px;
  height: 40px;
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  color: var(--accent-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Product-Info select {
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  /* color: #878787; */
  color: var(--text-color);
  margin-right: 15px;
  width: 80px;
  height: 40px;
  text-align: center;
  /* background: #FFFFFF; */
  background: var(--background-color);

  border: 1px solid #A6A6A6;
  box-sizing: border-box;
  border-radius: 10px;

}

.Product-Info button {
  border: none;
  width: 115px;
  height: 40px;

  background: var(--accent-color);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08), inset 0px 2px 15px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 18px;
  color: #EEEEEE;
  cursor: pointer;
}

.Product-Info button:active {
  opacity: 0.7;
}

.Product-Info-Box {
  /* background: #FFFFFF; */
  background: var(--background-color);

  box-shadow: 0px 4px 20px rgba(108, 108, 108, 0.08), inset 0px 4px 15px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 15px;
}

.Product-Effects {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.Product-Effect {
  width: 87px;
  height: 87px;
  /* background: #EEECF0; */
  margin-top: 20px;
  border-radius: 25px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.Product-Effects label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 23px; */
  /* color: #393939; */
  color: var(--text-color);
}

.Product-Effect img {
  width: 87px;
  height: 87px;
  margin-bottom: 2.5px;
}

.Product-Effect span {
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 15px;
  /* color: #878787; */
  color: var(--secondary-text-color);
  margin-top: 5px;
  margin-bottom: 0px;
}

.Product-Description {
  display: flex;
  flex-direction: column;
}

.Product-Description label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 23px; */
  /* color: #393939; */
  color: var(--text-color);
}

.Product-Description p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  /* line-height: 18px; */
  margin-left: 0px;
  /* color: #878787; */
  color: var(--secondary-text-color);
}


@media screen and (max-width: 800px) {

  .Product-Body h1 {
    font-size: 28px;
    margin-top: 5px;
    margin-bottom:15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .Product-Body .Product-Brand {
    font-size: 16px;
  }


  .Product .Back-Button {
    /* position: fixed; */
    left: 25px;
    top: 90px;
    /* margin-left: -30px; */
    /* top: 80px; */
  }
}


/* Products */
.Products-Body {
  flex-direction: row;
}

.Products-Search {
  margin-left: auto;

  font-size: 12px;
  margin-right: 0px;
  /* background: #FFFFFF; */
  background: var(--background-color);

  border: 1px solid #A6A6A6;
  /* color: #000; */
  color: var(--text-color);
  box-sizing: border-box;
  border-radius: 30px;
  height: 35px;
  outline: none;
  padding-left: 15px;
  width: 40%;
  margin-top: 0px;
  margin-bottom: 0px;


}

.Sort-Button {
  display: block;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  /* color: #878787; */
  color: var(--secondary-text-color);
  margin-right: 15px;
  text-align: center;
  /* background: #FFFFFF; */
  background: var(--background-color);

  border: 1px solid #A6A6A6;
  box-sizing: border-box;
  border-radius: 100px;
  cursor: pointer;
  height: 35px;
  width: 115px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.Mobile-Filter-Button {
  display: none;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  /* color: #878787; */
  color: var(--secondary-text-color);
  margin-right: 15px;
  text-align: center;
  /* background: #FFFFFF; */
  background: var(--background-color);

  border: 1px solid #A6A6A6;
  box-sizing: border-box;
  border-radius: 100px;
  cursor: pointer;
  height: 35px;
  width: 100px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.Products-Sidebar {
  /* background-color: red; */
  height: 100%;
  min-height: 500px;
  width: 175px;
  padding-right: 30px;
  margin-right: 30px;
  border-right: 1px solid rgb(215, 228, 235);
  overflow: hidden;
}

.Products-Sidebar h5 {
  font-size: 12px;
  font-weight: bold;
  margin-top: 35px;
  margin-bottom: 0px;
  color: var(--text-color);
}


.Products-Sidebar ul {
  list-style-type: none;
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 0px;
  margin-top: 15px;
}


.Products-Sidebar li {
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: 500;
  color: var(--secondary-text-color) !important;
}

.Products-Sidebar li:hover {
  color: var(--accent-color);
}

.Products-Sidebar .active {
  color: var(--accent-color) !important;
}

.RangeSlider {
  width: 70%;
}

.Products-Titlebar-Desktop {
  display: flex;
}

.Products-Titlebar-Mobile {
  display: none;
}


@media screen and (min-width: 800px) {
  .Products-Sidebar {
    display: block !important;
  }
}


@media screen and (max-width: 800px) {
  .Products-Titlebar-Mobile {
    display: flex;
  }

  .Products-Titlebar-Desktop {
    display: none;
  }

  .Products-Body {
    flex-direction: column;
  }

  .Products-Search {
    /* margin-left: 15px; */
    margin-right: 15px;
    /* width: 40%; */
    width: auto;
  }

  .Products-Sidebar {
    /* display: none; */
    margin-right: 0px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    width: 100%;
    border-right: none;
  }

  .Mobile-Filter-Button {
    display: block;
  }

}


/* Checkout */

.Checkout {
  padding: 30px;
  padding-top: 60px;
  max-width: 1400px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.Checkout h1 {
  color: var(--text-color);
}

.Checkout input {
  color: var(--text-color);
  /* background-color: #fff; */
  background-color: var(--background-color) !important;
}

.Checkout input::placeholder {
  color: var(--secondary-text-color);
}


@media screen and (max-width: 870px) {
  .App-Body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .Checkout-Wrapper {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }

  .Checkout-Container {
    width: 75% !important;
  }

  .Checkout-Total {
    width: 75% !important;
  }
}

.Checkout-Container {
  width: 400px;
}

.Checkout form {
  display: flex;
  flex-direction: column;
}


.Checkout-Total {
  width: 278px;
  height: 391px;
  background-color: rgba(241, 243, 246);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.Checkout label {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--text-color);
  /* color: #3C3C3C; */
  margin-bottom: 15px;
}

.Checkout input {
  outline: none;
  padding-left: 15px;
  margin-bottom: 15px;
  height: 45px;
  background: #F9F9F9;
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
  border-radius: 5px;
}

.Checkout select {
  outline: none;
  padding-left: 15px;
  margin-bottom: 15px;
  height: 45px;
  background: #F9F9F9;
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--text-color);

}


.Credit-Card-Form {
  /* background: #FFFFFF; */
  background: var(--background-color);

  border: 1px solid #D6D6D6;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.Checkout .Active-Option {
  border: 1px solid var(--accent-color) !important;
  color: var(--accent-color) !important;
}

.Card-Alert {
  background: #4AB119;
  border: 1px solid #2C8A00;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 12px;
  color: #FFFFFF;
  padding:12px;
  margin-top: 15px;
}

.Credit-Card-Form input {
  /* background-color: #FFFFFF; */
  background-color: var(--background-color);

}

.Credit-Card-Form select {
  color: var(--text-color);

  /* background-color: #FFFFFF; */
  background-color: var(--background-color);

}

.Checkout .side {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.Checkout .side input {
  width: auto;
  min-width: 0;
  /* margin-left: 2.5px; */
  /* margin-right: 2.5px; */
}


.Checkout .side select {
  width: auto;
  min-width: 0;
  color: var(--text-color);

  /* margin-left: 2.5px; */
  /* margin-right: 2.5px; */
}



.Checkout p {
  font-weight: normal;
  font-size: 12px;
  color: #858585;
  margin-left: 5px;
  margin-top: 17px;
}


.Checkout-Total img {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.Checkout-Total h5 {
  font-weight: bold;
  font-size: 14px;
  color: var(--text-color);
  /* color: #535353; */
  margin: 0px;
}

.Checkout-Total p {
  font-weight: 500;
  font-size: 11px;
  color: #797979;
  margin: 0px;
}

.Checkout-Total span {
  font-weight: 500;
  font-size: 12px;
  color: var(--accent-color);
  margin-top: 30px;
}

.Checkout-Subtotal {
  padding-top: 30px;
  margin-top: 15px;
  border-top: 0.5px solid #D9D9D9;
}

.Checkout-Total h6 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: var(--text-color);

  /* color: #535353; */
}

.Checkout-Subtotal p {
  font-weight: 500;
  font-size: 12px;
  color: #797979;
}

.Checkout-Button {
  width: 100%;
  height: 40px;
  background: var(--accent-color);
  box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  color: #fff;
  border: none;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .side1 {
    flex-direction: column !important;
  }
  .side1 div:nth-child(1) {
    padding-right: 0px !important;
  }

  .side1 div:nth-child(2) {
    padding-left: 0px !important;
  }

}



.form-error {
  color: red !important;
  margin-top: -15px !important;
}

/* footer */
.Footer {
  /* height: 65px; */
  /* width: 100%; */
  /* background-color: #F8F8F8; */
  /* border: 0.7px solid #eee; */
  /* border-top: 0.3px solid #D2D2D2; */
  padding-top: 15px;

  z-index: 1;
  text-align: center;
}

.Footer a {
  /* color: #000; */
  color: var(--text-color);
}


/* WIDGET */
.fadeInBottom {
  animation-name: fadeInBottom;
}

.fadeInTop {
  animation-name: fadeInTop;
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInBottom {
  from {
  opacity: 0;
  transform: translateY(100%);
  }

  to {
  opacity: 1
  }
}

.cssanimation {
animation-duration: 0.5s;
animation-fill-mode: both;
}

.Menu-Card {
    /* margin-left: 90px; */
    margin-bottom: 15px;
    cursor: pointer;
}

.Menu-Card h5 {
    margin-top: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* color: #7C7B7B; */
    color: var(--secondary-text-color);
}

.Menu-Card div {
    height: 150px;
    width: 150px;
    padding: 15px;
    background: var(--background-color);

    /* background: #FFFFFF; */
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Menu-Card img {
    width: 130px;
}

.red {
    border: 0.5px solid #DE0000;
}

.blue {
    border: 0.5px solid #190DFF;
}

.yellow {
    border: 0.5px solid #FF9D43;
}

.green {
    border: 0.5px solid #4AB119;
}

.light-blue {
    border: 0.5px solid #52E0FF;
}

.purple {
    border: 0.5px solid #D252FF;
}